@import "../../../styles/styles.scss";

.Recovery {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;

  &-FormControlButton {
    color: white !important;
    background-color: $blue-secondary !important;
  }

  &-Name {
    color: $blue-ink;
    margin-top: 0;
    font-weight: bold;
    text-align: center;
  }

  &-Password {
    color: $text-color !important;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  &-Button {
    width: 200px;
    margin-left: auto !important;
    margin-right: auto !important;
    
    &_Reset {
      background-color: $red !important;
    }
  }

}
