.AboutPage {
  align-items: center;
  color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 2rem;

  &-Text {
    font-size: 18px;
    text-align: center;
  }

  &-Button {
    margin-top: 2rem;
  }
}

#WalletPage .AboutPage {
  height: 50vh;
}
