@import "../../../styles/styles.scss";

.Transaction {
  align-items: center;
  color: $grey-primary;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-left: 10px;
  max-width: 600px;
  margin-left: 5px;

  @media (max-width: 522px) {
    font-size: 12px;
 }
  
  &-Direction {
    font-size: 18px;
    padding: 0 4px;
    @media (max-width: 522px) {
      font-size: 15px;
    }
  }
  
  &-Icon {
    color: $primary;
    font-size: 18px !important;
    position: relative;
    top: 3px;
    
    @media (max-width: 522px) {
      font-size: 12px !important;
   }
  }

  &-Address {
    font-size: 18px;
  }

  &-Amount {
    color: $primary;
    font-weight: bold;
    font-size: 20px;
    flex-grow: 1;
    padding: 0 4px;
    text-align: end;

    @media (max-width: 522px) {
      font-size: 12px;
   }
  }
}