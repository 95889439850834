@import "../../../styles/styles.scss";

.Transfer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;

  &-Name {
    color: $blue-ink;
    margin-top: 0;
    font-weight: bold;
    text-align: center;
  }

  &-Switches {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      margin: 0 0 3px !important;
    }
  }

  &-Switch {
    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      margin: 5px !important;
      > span {
        font-size: $small-font-size !important;
        margin: 0 2px 4px;
      }
    }
  }

  &-FormControl {
    margin: 5px 0 10px 0 !important;
  }
  
  &-Input {
    color: $text-color !important;
  }

  &-FormControlButton {
    background-color: $white !important;
    color: $blue-secondary !important;

    &_Hidden {
      color: $white !important;
    }
  }

  &-Buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }

  &-Button {
    width: 200px;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 40px !important;
  }
}
