@import "../../../styles/styles.scss";

.StepFour {
  align-items: center;
  display: flex;
  flex-direction: column;
  color: $white;

  &-Description {
    text-align: justify;

    @media screen and (max-width: 400px) {
      font-size: 14px;
      margin: $margin-normal;
    }
  }

  &-Form {
    display: grid;
    margin: $margin-normal;
  }

  &-FormControlLabel,
  &-FormControlButton {
    color: $white !important;
  }

  &-FormControl.MuiFormControl-root {
    margin-bottom: $margin-normal;
  }

  &-Button.MuiButton-root {
    margin-right: auto;
    margin-left: auto;
  }

  &-Button {
    width: 250px;
  }
}
