@import "../../../styles/styles.scss";

.Transactions {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  color: $grey-primary;
  //font-size: ;
  padding-bottom: 4px;
  height: 85vh;

  @media (max-width: 500px) {
    min-width: 250px;
  }
  &-Date {
    font-size: 20px;
    position: relative;
    left: -7px;

    @media (max-width: 522px) {
      font-size: 18px;
   }
  }
  
  &-Days {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  &-Day {
    padding-bottom: 6px;
    margin: 0 7px;
  }

  &-Arrow {
    cursor: pointer;
    display: inline-block;
    font-family: monospace;
    font-size: 22px;
    font-weight: bold;
    user-select: none;
    position: relative;
    top: -1px;

    @media (max-width: 522px) {
      font-size: 18px;
   }
 
    &_Down {
      transform: rotate(0.25turn);
      top: 0;
      left: 1px;
    }
  }

  &-TransactionRow {
    font-size: 12px;
    margin: 3px 0;
    display: none;

    &_Open {
      display: block;
    }
  }

  &-Close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 0 0;
    margin:15px 0 0 0;
  }

  &-Empty{
    margin: auto;
  }
}
