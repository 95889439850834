@import "../../../styles/styles.scss";

.ImportAccount {
  align-items: center;
  background-color: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30vh;

  &-SeedPanel {
    height: 30vh;
    min-height: 200px;
  }

  &-Form {
    display: grid;
    width: 60vw;
  }

  &-FormControl.MuiFormControl-root {
    margin-bottom: 10px;
  }

  &-FormControlLabel,
  &-FormControlButton {
    color: $white !important;
  }

  &-Button.MuiButton-root {
    display: block;
    margin: 0 auto $margin-normal;
    min-width: 100px;

    &:nth-of-type(1) {
      margin-top: $margin-normal;
    }
  }
}
