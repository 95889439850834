$margin-normal: 20px;
$margin-bottom-normal: 20px;
$fiatFontSize: 12px;

$round-button-size: 40px;

$page-title-font-size: 20px;

$screen-xs-width: 390px;
$screen-xs-heigh: 770px;

$screen-s-width: 1000px;
$screen-s-heigh: 930px;

$small-font-size: 14px;
