@import "../../../styles/styles.scss";

.DemoHeader {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding: 26px 0px;

  @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
    padding: 10px 0px 30px;
  }

  &-Divider {
    border: none;
    background-color: grey;
    width: 30%;
  }

  &-Amounts {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 128px;
    margin: auto;

    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      height: 90px;
    }
  }

  &-Amount {
    font-size: 25px;
    margin: auto;
    padding-left: 5px;
  }

  &-Tokens {
    color: $blue-secondary;
    position: absolute;
    bottom: -17px;
    font-size: 13px;
    width: 100%;
    text-align: center;
  }

  &-Progress {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    top: 5px;
    left: 8px;
  }
}
