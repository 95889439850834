@import "../../../styles/styles.scss";

.SeedPanel {
  // border: $grey-light 1px solid;
  border-radius: 5px;
  display: block;
  height: 50vh;
  margin: $margin-normal auto;
  max-height: 600px;
  max-width: 800px;
  min-height: 300px;
  min-width: 300px;
  overflow: auto;
  padding: 5px;
  width: 80vw;

  &-Body {
    height: 70%;
  }

  &-Footer {
    height: 30%;
    position: relative;
  }

  &-Warning {
    bottom: 1px;
    color: red;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
