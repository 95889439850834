@import "../../../styles/styles.scss";

.CreateAccountPage {
  display: block;

  &-Footer {
    background-color: $primary;
    bottom: 0;
    display: flex;
    position: fixed;
    height: 120px;
    width: 100%;

    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      height: 0;
    }
  }

  &-Logo {
    margin: auto;
    height: 50px; 
  }
}