@import "../../../styles/styles.scss";

.StepOne {
  align-items: center;
  display: flex;
  flex-direction: column;
  color: $white;

  &-SeedPanel {
    height: 30vh !important;
    min-height: 220px !important;
  }

  &-Description {
    text-align: justify;

    @media screen and (max-width: 400px) {
      font-size: 14px;
      margin: 0;
    }
  }

  &-Button {
    width: 250px;
  }
}
