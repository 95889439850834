@import '../../../styles/styles.scss';

.DemoPage {
  background-color: rgb(232, 240, 254);
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  height: 100vh;
  min-width: 350px;

  &-Toolbar {
    align-items: flex-start;
    flex-direction: column;
    padding-top: 5px;
    min-height: 160;
    padding-bottom: 0;
  }

  &-ToolbarHeader {
    display: flex;
    border-bottom: grey 1px solid;
    width: 100%;
  }

  &-ToolbarHeaderItems {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: -15px;
  }

  &-ToolbarHeaderItemsEdgeStart {
    margin-left: -15px;
  }

  &-ToolbarBody {
    margin: auto;
    position: relative;
    width: 100%;
  }

  &-Transaction {
    margin-bottom: 5px;

    &_Mint {
      margin: 0 auto 5px;
    }
  }

  &-Input {
    width: 220px;
    margin-right: 10px;

    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      font-size: $small-font-size !important;
      margin: 0;
      width: 180px;
    }
  }

  &-Buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-Button {
    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      font-size: $small-font-size !important;
      margin-left: 10px !important;
    }

    &_Transfer,
    &_Log {
      margin: 26px 10px 0 !important;
    }
  }

  &-TransferTo {
    margin-bottom: 8px;
    width: 355px;
  }

  &-Wrapper {
    display: flex;
    margin: 50px auto;
    flex-direction: column;

    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      margin: 26px auto;
    }
  }

  &-Info {
    background-color: $blue-light !important;
    display: flex;
    flex-direction: column;
    margin: auto auto 130px;
    padding: 20px;

    @media (max-width: $screen-s-width), (max-height: $screen-xs-heigh) {
      font-size: $small-font-size;
      margin: auto 10px 90px;
    }
  }

  &-InfoTitle {
    @media (max-width: $screen-xs-width), (max-height: $screen-s-heigh) {
      height: 0;
      overflow: hidden;
    }
  }

  &-InfoText {
    margin-bottom: 6px;
  }

  &-Footer {
    background-color: $primary;
    bottom: 0;
    display: flex;
    position: fixed;
    height: 120px;
    width: 100%;

    @media (max-width: $screen-s-width), (max-height: $screen-xs-heigh) {
      height: 80px;
    }
  }

  &-Logo {
    margin: auto;
    height: 60px;

    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      height: 40px;
    }
  }

  &-Version1,
  &-Version2,
  &-Version3 {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 14px;
    color: $blue-light;
  }

  &-Version2 {
    top: 20px;
  }

  &-Version3 {
    top: 36px;
  }

  &-AboutPage {
    height: 50vh;
  }

  &-ZeroPaddingRight {
    padding-right: 0;
  }

  &-Transfer {
    padding: 22px 20px 30px 11px;
    border: 1px solid #d8dbf1;
    margin: 24px -28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8fafb;
  }
}
