@import "../../../styles/styles.scss";

.Welcome {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 2rem;

  // need to owerwrite 'MuiButton-root' class
  &-Button.MuiButton-root {
    border-radius: 8px;
    margin-bottom: $margin-bottom-normal;
    max-width: 400px;
    min-width: 200px;
    padding: 12px;
    width: 50vw;

    @media screen and (max-width: 400px) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  &-Description {
    text-align: center;
    font-size: 4vw;

    @media screen and (min-width: 350px) {
      font-size: 18px;
    }
  }

  &-Greeting {
    margin-bottom: 10px;
    font-size: 6vw;

    @media screen and (min-width: 800px) {
      font-size: 48px;
    }
  }

  &-Logo {
    width: 40vw;
    max-width: 300px;
    margin-bottom: 20px;
    min-width: 150px;
  }
}
